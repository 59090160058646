const menuBtn = document.querySelector('.menu-btn');
let menuOpen = false;
let navi = document.querySelector('.nav-list');
let bgeffects = document.querySelector('.background-nav-filters');

menuBtn.addEventListener('click', () => {
    if(!menuOpen) {
        menuBtn.classList.add('open');
        menuOpen = true;
        navi.classList.add('show');
        bgeffects.classList.add('effects');

    } else {
        menuBtn.classList.remove('open');
        menuOpen = false;
        navi.classList.remove('show');
        bgeffects.classList.remove('effects');

    }
});





